import { Outlet, Navigate } from 'react-router';
import { useFirebaseAuth } from './context/AuthContext.js';
import { Layout, Row } from 'antd';
import Sider from 'antd/lib/layout/Sider.js';
import { Content } from 'antd/lib/layout/layout.js';

import LoggedHeader from './components/logged/Header.js';
import LoggedMenu from './components/logged/Menu.js';

import './assets/css/menu.css'

const ProtectedRoute = (props) => {
  const a = useFirebaseAuth();
  const rol = a?.user?.rol;
  let go = false;
  //1. Para otorgar permisos a los usuarios según roles tendremos que actualizar este switch en protectedRoutes, pero tambien el menu.js, mostrando las etiquetas o no correspondientes al rol concreto. Si hay nuevas rutas, además, tendremos que actualizar Views.js
  switch (props.p) {
    case 'cron':
      go = (rol?.indexOf('admin') > -1) || rol?.indexOf('admingetronics') > -1 || rol?.indexOf('adminlodge') > -1;
      break;
    case 'cardealer':
      go = (rol?.indexOf('admin') > -1) || rol?.indexOf('admingetronics') > -1 ; //|| rol?.indexOf('adminlodge') > -1
      break;
      case 'cardlodge':
      // este case es igual que concesionarios pero solo para las tarjetas de alojamientos:
      // creamos un nuevo rol para HOTELES que muestre otro menu distinto a concesionarios. Solución sencilla, no la mejor desde luego
      go = (rol?.indexOf('adminlodge') > -1);
      break;
    case 'company':
      go = rol?.indexOf('admin') > -1 || rol?.indexOf('adminlodge') > -1;
      break;
    case 'dashboard':
      go = true;
      break;
    default:
  }
  return go ?
    <Layout style={{ height: '100vh' }}>
      <LoggedHeader />
      <Layout>
        <Row style={{ height: '100%' }}>
          <Sider collapsible='true'
            style={{ height: 'inherit', backgroundColor: 'white', width: 'auto', minHeight: '90vh' }}>
            <LoggedMenu />
          </Sider>
          <Content style={{ width: 'min-content' }}>
            <Outlet />
          </Content>
        </Row>
      </Layout>
    </Layout>
    : <Navigate to='/' />;
}

export default ProtectedRoute