import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from '../context/AuthContext';
import { getImageUrl, uploadFile } from '../context/firebaseConfig'; //,saveData

import { Button, Collapse, Form, Input, Layout, Row, notification } from 'antd';

import ImageUrl from '../components/ImageUrl';

import '../assets/css/main.css'

const { Panel } = Collapse;

const Company = () => {
    const { t } = useTranslation();
    const a = useFirebaseAuth();
    const [api, contextHolder] = notification.useNotification();
    const [logoForm] = Form.useForm();
    const [chatForm] = Form.useForm();
    const [dbConfigForm] = Form.useForm();
    const [compDataForm] = Form.useForm();
    // eslint-disable-next-line no-unused-vars
    const [img, setImg] = useState();
    const [file, setFile] = useState();
    const [imgCmp, setImgCmp] = useState();
    const company = a.company;

    useEffect(() => {
        const searchImg = async () => {
            let pretty = [];
            const i = await getImageUrl(`logos/${company.companyID}`, 'default');
            setImg(i);
            pretty.push(<ImageUrl imgsrc={i || ""} sufix={company.companyID} key='imgUrl_1' callback={setFile} />)
            setImgCmp(pretty);
        }
        searchImg()
        // pretty.push(<ImageUrl imgsrc={cd?.imageUrl_en||""} urllnk={cd?.url_en||""} sufix='en' key='imgUrl_3' callback={handleCallback} />)
    }, [company.companyID]);

    const openNotificationWithIcon = (type) => {
        api[type]({
            // queremos que sea == y no ===. Está hecho a propósito
            // eslint-disable-next-line eqeqeq
            message: type == 'success' ? t('DATA_SAVED_TITLE') : t('ERROR_DATA_SAVED_TITLE'),
            // eslint-disable-next-line eqeqeq
            description: type == 'success' ? t('DATA_SAVED_BODY') : t('ERROR_DATA_SAVED_BODY'),
        });
    };

    const saveChatCfg = async (e) => {
        const ret = await saveCfg(e, 'chat')
        // console.log(ret)
        if (ret) chatForm.resetFields();
    }
    const saveDbCfg = async (e) => {
        const ret = await saveCfg(e, 'dbConfig');
        // console.log(ret);
        if (ret) dbConfigForm.resetFields();
    }
    //TODO: TUTORIA NO ME FUNCIONA GUARDAR LOS DATOS, PORQUE ME CREA UN MAP EN LA COMPANY. He dejado el boton comentado para que no me de error.
    const saveComData = async (e) => {
        const ret = await saveCfg(e);
        // console.log(ret);
        if (ret) compDataForm.resetFields();
    }


    const saveLogoCfg = async (e) => {
        // Si tenemos imagen la subimos
        if ((e?.dragger && e.dragger[0]) || file) {
            const ret = await uploadFile(`logos/${company.companyID}`, e.dragger[0].originFileObj || file, 'default');
            openNotificationWithIcon(ret ? 'success' : 'error');
            return;
        }
    }

    const saveCfg = async (e, prefix) => {
        let data = {};
        const keys = Object.keys(e);
        for (var i in keys) {
            const key = keys[i];
            if (e[key] !== undefined && e[key].length > 0) {
                if (key === 'templateBodyParams') { data[`${prefix}.templateBodyParams`] = e[key].replaceAll(" ", "").split(','); }
                if (key === 'checkDays') { data[`${prefix}.checkDays`] = Number(e[key]) || 2 } // coge el numero ok o 2 por defecto
                else { data[`${prefix}.${key}`] = e[key]; }
            }
        }
        // const ret = await saveData({ coll: `companies/${company.companyID}`, data })
        const ret = await a.refreshCmp(data);
        openNotificationWithIcon(ret ? 'success' : 'error'); //esta notificacion no se mostrará porque estamos actualizando el contexto de company, como se refresca la pagina se pierde, y tendriamos que guardar este ret en el contexto tambien para poderlo recuperal, lo cual no tiene mucho sentido. 
        return ret;
    }

    return (
        <>
            {contextHolder}

            <Layout style={{ height: '100vh' }}>
                <Row className='centered' style={{ minWidth: '90%', margin: '0 auto', marginTop: '5%' }}>
                    <Row style={{ minWidth: '100%', marginBottom: '3%' }}>
                        <Collapse style={{ width: '100%' }} acordion>
                            {/* TODO:TUTORIA Y GEMA ANTES mirar como se puede guardar estos datos para que se pueda actualizar el nombre de la compañia por ejemplo o el mail de soporte.... porque la prueba inicial no me la guarda */}
                            <Panel
                                style={{ backgroundColor: "#e8f2f9" }}
                                // header={t('DATABASE_CONFIG')}
                                header={'Datos de la compañía'}
                                key='company_data_form'>
                                <Form name="companyDataForm"
                                    // onFinish={saveComData} // TODO: TUTORIA DESCOMENTAR PARA PROBAR GUARDAR DATOS
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 15 }}
                                    layout="horizontal"
                                    size='default'
                                    form={compDataForm}
                                >
                                    <Form.Item label={'Compañia'} tooltip={'Nombre de la compañía'} name='companyName'>
                                        <Input defaultValue={company.companyName} disabled={true} />
                                        {/* <Input defaultValue={company.companyName} disabled={true}/> */}
                                    </Form.Item>
                                    <Form.Item label={'Contacto Soporte'} tooltip={'Correo electrónico de soporte'} name='mail_support' disabled>
                                        <Input defaultValue={company.mail_support} disabled={true} />
                                    </Form.Item>
                                    {/* // TODO: TUTORIA DESCOMENTAR PARA PROBAR GUARDAR DATOS */}
                                    {/* <Form.Item wrapperCol={{ offset: 8, span: 15 }}>
                                        <Button type="primary" htmlType="submit" >{t('BUTTON_SAVE')}</Button>
                                    </Form.Item> */}


                                    {/* <Form.Item label={t('DB_HOST')} tooltip={t('DB_HOST_TOOLTIP')} name='host'>
                                        <Input defaultValue={company.dbConfig.host} />
                                    </Form.Item>
                                     */}
                                </Form>
                            </Panel>

                            <Panel
                                style={{ backgroundColor: "#e8f2f9" }}
                                header={t('LOGO')}
                                key='company_logo_form_1'
                            >
                                <Form
                                    name={`logoForm}`}
                                    onFinish={saveLogoCfg}
                                    scrollToFirstError
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 15 }}
                                    layout="horizontal"
                                    size='default'
                                    form={logoForm}
                                >
                                    {imgCmp}
                                    <Form.Item wrapperCol={{ offset: 8, span: 15 }}>
                                        <Button type="primary" htmlType="submit" >{t('BUTTON_SAVE')}</Button>
                                    </Form.Item>

                                </Form>
                            </Panel>
                            <Panel
                                style={{ backgroundColor: "#e8f2f9" }}
                                header={t('CHAT_CONFIG')}
                                key='1'>
                                <Form name="chatPropsForm"
                                    onFinish={saveChatCfg}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 15 }}
                                    layout="horizontal"
                                    size='default'
                                    form={chatForm}
                                >

                                    {/* NOTA IMPORTANTE: defaultValue en los campos channelId y templateNamespace de la configuración del chat, podrá estar configurada en Company si todos los concesioanrios utilizan el mismo channel de MB y un mismo flow, o en DEALERSHIP, si cada concesionario necesita de su propio channel porque tienen telefónos distintos para realizar las encuestas. Para estos campos se añade un mensaje por defecto en el caso que el campo esté en blanco*/}
                                    <Form.Item label={t('CHAT_CHECKDAYS')} tooltip={t('CHAT_CHECKDAYS_TOOLTIP')} name='checkDays'>
                                        {/* al ser parametrizable este campo lo configuramos desde aqui, el metodo saveCfg() nos lo guardará como Number si es un numero o 2 si se introduce algo no valido (letras o comas) */}
                                        <Input defaultValue={company.chat.checkDays} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_API')} tooltip={t('CHAT_API_TOOLTIP')} name='api'>
                                        <Input defaultValue={company.chat.api} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_CHANNEL_ID')} tooltip={t('CHAT_CHANNEL_ID_TOOLTIP')} name='channelId'>
                                        <Input defaultValue={company.chat.channelId || t('CHAT_MSG_NO_CONFIG')} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_KEY')} tooltip={t('CHAT_KEY_TOOLTIP')} name='key'>
                                        <Input defaultValue={company.chat.key} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_KEY_SIGNING')} tooltip={t('CHAT_KEY_SIGNING_TOOLTIP')} name='key_signing'>
                                        <Input defaultValue={company.chat.key_signing} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_TEMPLATE_NAME')} tooltip={t('CHAT_TEMPLATE_NAME_TOOLTIP')} name='templateName'>
                                        <Input defaultValue={company.chat.templateName} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_TEMPLATE_NAMESPACE')} tooltip={t('CHAT_TEMPLATE_NAMESPACE_TOOLTIP')} name='templateNamespace'>
                                        <Input defaultValue={company.chat.templateNamespace || t('CHAT_MSG_NO_CONFIG')} />
                                    </Form.Item>

                                    <Form.Item label={t('CHAT_TEMPLATE_PARAMS')} tooltip={t('CHAT_TEMPLATE_PARAMS_TOOLTIP')} name='templateBodyParams'>
                                        <Input defaultValue={(company.chat.templateBodyParams).map((element) => { return ` ${element}` })} />
                                    </Form.Item>

                                    <Form.Item wrapperCol={{ offset: 8, span: 15 }}>
                                        <Button type="primary" htmlType="submit" >{t('BUTTON_SAVE')}</Button>
                                    </Form.Item>
                                </Form>
                            </Panel>

                            <Panel
                                style={{ backgroundColor: "#e8f2f9" }}
                                header={t('DATABASE_CONFIG')}
                                key='2'>
                                <Form name="databasePropsForm"
                                    onFinish={saveDbCfg}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 15 }}
                                    layout="horizontal"
                                    size='default'
                                    form={dbConfigForm}
                                >
                                    <Form.Item label={t('DB_DATABASE')} tooltip={t('DB_DATABASE_TOOLTIP')} name='database'>
                                        <Input defaultValue={company.dbConfig.database} />
                                    </Form.Item>
                                    <Form.Item label={t('DB_HOST')} tooltip={t('DB_HOST_TOOLTIP')} name='host'>
                                        <Input defaultValue={company.dbConfig.host} />
                                    </Form.Item>
                                    <Form.Item label={t('DB_USER')} tooltip={t('DB_USER_TOOLTIP')} name='user'>
                                        <Input defaultValue={company.dbConfig.user} />
                                    </Form.Item>
                                    <Form.Item label={t('DB_PASSWORD')} tooltip={t('DB_PASSWORD_TOOLTIP')} name='password'>
                                        <Input defaultValue={company.dbConfig.password} />
                                    </Form.Item>
                                    <Form.Item label={t('DB_PORT')} tooltip={t('DB_PORT_TOOLTIP')} name='port'>
                                        <Input defaultValue={company.dbConfig.port} />
                                    </Form.Item>
                                    <Form.Item label={t('DB_PROVIDER')} tooltip={t('DB_PROVIDER_TOOLTIP')} name='provider'>
                                        <Input defaultValue={company.dbConfig.provider} />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 8, span: 15 }}>
                                        <Button type="primary" htmlType="submit" >{t('BUTTON_SAVE')}</Button>
                                    </Form.Item>
                                </Form>
                            </Panel>
                        </Collapse>
                    </Row>
                </Row>
            </Layout>
        </>
    )
}

export default Company;