import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFirebaseAuth } from '../context/AuthContext';
import { getData } from '../context/firebaseConfig';

import { Button, Collapse, Form, Layout, Row, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CardLodgeForm from '../components/CardLodgeForm';

import '../assets/css/main.css'

const CardLodge = () => {
    const { t } = useTranslation();
    const a = useFirebaseAuth();
    const company = a.company;
    const [api, contextHolder] = notification.useNotification();
    const [dealers, setDealers] = useState([])
    const [newDealer, setNewDealer] = useState();

    useEffect(() => {
        getDealers();
    }, []);

    const getDealers = async () => {
        const dds = []
        const data = await getData({ coll: `companies/${company.companyID}/dealership` });
        data.forEach(d => {
            dds.push(<CardLodgeForm {...d} key={d.dealerId} cb={savedRemoved} />)
        })
        setDealers(dds)
    }

    const openNotificationWithIcon = (type, msg) => {
        api[type]({
            // eslint-disable-next-line eqeqeq
            message: type == 'success' ? t('DATA_SAVED_TITLE') : t('ERROR_DATA_SAVED_TITLE'),
            description: 'success' ? msg || t('DATA_SAVED_BODY') : msg || t('ERROR_DATA_SAVED_BODY'),
        });
    };

    const add = () => {
        setNewDealer(<CardLodgeForm key='1' cb={savedRemoved} />)
    }

    const savedRemoved = async (code, msg) => {
        switch (code) {
            case -1:
                openNotificationWithIcon('error', msg);
                break;
            case 1:
                openNotificationWithIcon('success', t('CORRECTLY_DELETED'));
                await getDealers();
                break;
            default:
                openNotificationWithIcon('success');
                await getDealers()
                setNewDealer(null);
        }
    }

    return (
        <>
            {contextHolder}

            <Layout style={{ height: '100vh' }}>
                <Row className='centered' style={{ minWidth: '90%', margin: '0 auto', marginTop: '5%' }}>
                    {!newDealer ?
                        <Row>
                            <Form.Item>
                                <Button style={{backgroundColor: "#e8f2f9"}} type="dashed"  onClick={() => add()} block icon={<PlusOutlined />}>
                                    {t('ADD_LODGE')}
                                </Button>
                            </Form.Item>
                        </Row>
                        :
                        <Row style={{ minWidth: '100%', marginBottom: '3%' }}>
                            <Collapse style={{ width: '100%' }} defaultActiveKey={['1']} acordion>
                                {newDealer}
                            </Collapse>
                        </Row>
                    }
                    <Row style={{ minWidth: '100%', marginBottom: '3%' }}>
                        <Collapse style={{ width: '100%' }} acordion>
                            {dealers}
                        </Collapse>
                    </Row>
                </Row>
            </Layout>
        </>
    )
}

export default CardLodge;