import { Layout, Row } from 'antd';
// import { useTranslation } from 'react-i18next';

import CronCard from '../components/CronCard';

import '../assets/css/main.css'
import '../assets/css/button.css'

const Crons = () => {
    return (
        <Layout>
            <Row className='centered' style={{ width: '90%', margin: '0 auto', marginTop: '5%' }}>
                <CronCard cron='asker' />
            </Row>
            <Row className='centered' style={{ width: '90%', margin: '0 auto', marginTop: '5%' }}>
                <CronCard cron='launcher' />
            </Row>
        </Layout>
    )
}

export default Crons;