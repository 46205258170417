import React, { useEffect, useState } from "react"

import { Content } from "antd/es/layout/layout";

// import LanguageSelector from "./langSelector/LanguageSelector";

const PicturePreview = (props) => {
    const [preview, setPreview] = useState(props.preview);

    // const fileUploaded = ev => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(ev.target.files[0])
    //     reader.onloadend = ev => {
    //         setPreview(reader.result);
    //     }
    // }

    useEffect(() => {
        if (props.file) {
            const reader = new FileReader()
            reader.readAsDataURL(props.file)
            reader.onloadend = ev => {
                setPreview(reader.result);
            }
        } else {
            setPreview(null);
        }
    }, [props])

    return (
        <Content>
            {preview &&
                <img
                    className='previewFile'
                    src={preview}
                    alt='preview'
                    style={{ maxWidth: '100%' }}
                />
            }
        </Content>
    )
}

export default PicturePreview;