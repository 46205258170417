import React, { useState } from 'react'
import i18resources from '../../i18resources'
import i18next from "i18next"

import { Menu, Dropdown, Button } from 'antd'

import "/node_modules/flag-icons/css/flag-icons.min.css";


const LanguageSelector = () => {
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || 'es');
    const changeLang = (code) => {
        // e.preventDefault();
        setCurrentLang(code);
        i18next.changeLanguage(code);
    }

    const menu = (
        <Menu className='d-flex flex-start flex-column' key='langselector' >
            {i18resources.langList.map(({ code, name, country_code }) => (
                <Menu.Item key={code}>
                    <Button
                        icon={<span className={`fi fi-${country_code} mx-2`}></span>}
                        onClick={() => changeLang(code)}
                        disabled={currentLang === code ? 'disabled' : ''}
                        key={`btn_${code}`}
                    >
                        <span style={{ 'paddingLeft': '5px' }}> {name} </span>
                    </Button>
                </Menu.Item>
            ))}
        </Menu>
    );

    const DropdownMenu = () => (
        <Dropdown key="languageSelector" overlay={menu} >
            <Button
                type="primary"
                shape="round"
                key='petete'
                icon={<i18resources.GlobeIcon2 />}
            />
        </Dropdown>
    );

    return (<DropdownMenu />)
}

export default LanguageSelector;