
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { app, getAuth, getData, logOut, pathPart, saveData } from "./firebaseConfig";

import Loading from '../components/Loading'

const Auth = React.createContext({ name: '', user: null });

const AuthContext = ({ children }) => {
    const nav = useNavigate();
    const [user, setUsuario] = useState(null);
    const [company, setCompany] = useState(null);
    const [showChild, setShowChild] = useState(false);

    const callbackDef = async (data=null) => {
        if(data) {
            const cmpStr = pathPart(window.location.host, 0);
            const retSave = await saveData({coll: `companies/${company?.companyID}`, data});
            if(retSave) {
                const company = (await getData({coll: `companies`, wee: ['companyPath', '==', cmpStr]}))[0];
                setCompany(company);
            }
        }
    }

    useEffect(() => {
        const au = getAuth(app);
        au.onAuthStateChanged(async (user) => {
            const cus = au.currentUser;
            const hayUser = cus != null;
            if (hayUser) {
                const cmpStr = pathPart(window.location.host, 0);
                const comp = (await getData({ coll: `companies`, wee: ['companyPath', '==', cmpStr] }))[0];

                if (comp?.companyID) {
                    const dbUser = (await getData({ coll: `users/${comp?.companyID}/users/${user?.email}` }))[0];
                    // CLARIFICATIONS
                    // esto es por que si no existe el usuario en la compañia no puede hacer login, al tener los usuarios de varias compañias en la misma BD
                    // se puede plantear este problema, imaginate que uno de proasas intenta entrar en wombat, aunque por menu no va a ver nada ni tendrá acceso a las pags
                    // estará logeado y eso no puede ser
                    if (dbUser) {
                        user.rol = dbUser.rol;
                        setUsuario(user);
                        setCompany(comp);
                        // nav('/company'); como company no se muestra en todos los roles tenemos que cambiarlo a uno comun
                        nav('/cron')
                    } else {
                        logOut();
                        setUsuario(null);
                        setCompany(null);
                        nav('/');
                    }
                }
            } else {
                setUsuario(null);
                setCompany(null)
                nav('/');
            }
            setShowChild(true);
        });
    }, []); //OJO probado: con nav aqui no muestra el menu compañia

    if (!showChild) {
        return <Loading />;
    } else {
        return (
            <Auth.Provider value={{ user, company, callbackDef }} key={+new Date()}>
                {children}
            </Auth.Provider>
        );
    }
};


function useFirebaseAuth() {
    const context = React.useContext(Auth);
    if (context === undefined) {
        throw new Error("useFirebaseAuth must be used within a FirebaseAuthProvider");
    }
    return { user: context.user, company: context.company, refreshCmp: context.callbackDef };
}

export { Auth, AuthContext, useFirebaseAuth };