import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Upload, Form, Layout } from 'antd'
import { InboxOutlined } from '@ant-design/icons';

import PicturePreview from "./PicturePreview";

// import { AD, FR, ES, GB, PT } from 'country-flag-icons/string/3x2'
import htmlToReact from "html-to-react";
import '../assets/css/imageUrl.css'

const { Content } = Layout

const ImageUrl = (props) => {
    const { t } = useTranslation();
    const [file, setFile] = useState();
    // eslint-disable-next-line no-unused-vars
    const Parser = htmlToReact.Parser();

    const { imgsrc, sufix } = props;
    const fileUploaded = ev => {
        setFile(ev);
        props.callback(ev);
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            // eslint-disable-next-line eqeqeq
            if (e.length == 0) setFile(null);
            return e;
        }
        // eslint-disable-next-line eqeqeq
        if (e?.fileList.length == 0) setFile(null);

        return e && e.fileList;
    };

    return (
        <React.Fragment key={`imgurl_${sufix}`}>
            <Content style={{ display: "flex", width: "100%", margin: "0 auto", marginBottom: "20px" }}>
                <Layout style={{ maxWidth: "450px", width: '40%' }}>
                    {/* <span className="flag">{Parser.parse(sufix == 'pt' ? PT : sufix == 'fr' ? FR :sufix == 'en' ? GB : sufix == 'es' ? ES : AD)}</span> */}
                    {file ?
                        <PicturePreview file={file} />
                        :
                        <img src={imgsrc} alt={imgsrc} />
                    }
                </Layout>
                <Layout style={{ width: '100%' }}>
                    <Form.Item label={t('IMAGE')} wrapperCol={{ offset: 0, span: 15 }} labelCol={{ offset: 0, span: 4 }}>
                        <Form.Item name="dragger" valuePropName={`fileList_${sufix}`} getValueFromEvent={normFile} >
                            <Upload.Dragger name="files" action={fileUploaded} style={{ maxHeight: "150px" }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t('SELECT_FILE')}</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </Layout>
            </Content>
        </React.Fragment>
    )
}

export default ImageUrl;