import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFirebaseAuth } from '../../context/AuthContext';

import { Menu } from "antd";
import { ApiOutlined, BankOutlined, CarOutlined, ShopOutlined } from "@ant-design/icons"; //PieChartOutlined

import '../../assets/css/menu.css'

const LoggedMenu = () => {
    const location = useLocation();
    const [config] = useState(useFirebaseAuth())
    const user = config.user;
    // const company = config.company;
    const { t } = useTranslation();
    const [items, setItems] = useState([]);

    useEffect(() => {
        const its = []
        if (user) {
            //2. aqui definimos el orden de cómo se muestra el menú, de arriba a abajo y según roles de Protectedroutes
            if (user?.rol.indexOf('admin') > -1) {
                // its.push({label: <Link to='/dashboard' >{t('DASHBOARD')}</Link>, key: '/dashboard', icon: <PieChartOutlined />});
                its.push({ label: <Link to='/cron' >{t('CRON')}</Link>, key: '/cron', icon: <ApiOutlined /> });
                its.push({ label: <Link to='/cardealer' >{t('CARDEALER')}</Link>, key: '/cardealer', icon: <CarOutlined /> });
                its.push({ label: <Link to='/company' >{t('COMPANY')}</Link>, key: '/company', icon: <BankOutlined /> });
                // Esto es por si hay que capar opciones a usuarios
            } else if (user?.rol.indexOf('admingetronics') > -1) {
                its.push({ label: <Link to='/cron' >{t('CRON')}</Link>, key: '/cron', icon: <ApiOutlined /> });
                its.push({ label: <Link to='/cardealer' >{t('CARDEALER')}</Link>, key: '/cardealer', icon: <CarOutlined /> });
            } else if (user?.rol.indexOf('adminlodge') > -1) {
                // creamos un nuevo rol para HOTELES que muestre otro menu distinto a concesionarios. Solución sencilla, no la mejor desde luego
                its.push({ label: <Link to='/cron' >{t('CRON')}</Link>, key: '/cron', icon: <ApiOutlined /> });
                its.push({ label: <Link to='/cardlodge' >{t('LODGE')}</Link>, key: '/cardlodge', icon: <ShopOutlined /> });
                // podriamos reutilizar tb la ruta, pero en la ruta aparecerá /cardealer que no tiene mucho sentido.
                // its.push({ label: <Link to='/cardealer' >{'puturru'}</Link>, key: '/cardealer', icon: <ShopOutlined /> });
                its.push({ label: <Link to='/company' >{t('COMPANY')}</Link>, key: '/company', icon: <BankOutlined /> });
            }
        }
        setItems(its);
    }, [user, t])

    // const getDefLoc = () => {
    //     // return useLocation().pathname;
    // }

    // const getItems = () => {
    //     return items;
    // }

    return (
        (items?.length) > 0 ?
            <Menu items={items}
                defaultSelectedKeys={location.pathname}
                // mode="inline"
                theme="light"
                className="vh100"
            >
            </Menu>
            :
            <></>
    )
}

export default LoggedMenu;