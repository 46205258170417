import { useEffect } from 'react';
import './App.css';
// import "antd/dist/antd.min.css";
import 'antd/dist/reset.css';

import Views from './Views'
import { pathPart } from './context/firebaseConfig';

const App = () => {
  //Funcion adicional para poner la primera letra en mayuscúla de una cadena de texto
  function capitalizeFirst(str) {
    if (typeof str !== 'string') {
      throw new Error('El argumento debe ser una cadena de texto.');
    }
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  //Este usseEffect actualiza dinamicamente el favicon y el title de la pestaña del panel en funcion del nombre del host cuando buscamos el companyPath en la url 
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/assets/img/${pathPart(window.location.host, 0)}/icon.png`;

    document.title = `${capitalizeFirst(pathPart(window.location.host, 0))} - Panel de mando`;

  }, [window.location.host]);


  return (
    <Views />
  )
}

export default App;