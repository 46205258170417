import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { app, getAuth, pathPart, signInWithEmailAndPassword } from "../context/firebaseConfig"; //setSessionID

import { Layout, Col, Row, Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import '../assets/css/login.css'

const Login = (props) => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const { Footer } = Layout;
    const [error, seterror] = useState('')
    const [auth, setAuth] = useState();

    useEffect(() => {
        setAuth(getAuth(app));
    }, []);

    const withEmail = async e => {
        //e.preventDefault();
        const { user, password } = e;
        await signInWithEmailAndPassword(auth, user, password)
            .then(async result => {
                // return nav('/dashboard'); // nav(to);
                // return nav('/company'); como company no se muestra en todos los roles tenemos que cambiarlo a uno comun
                return nav('/cron')
            })
            .catch(error => {
                // console.log(error);   //error.code   //error.message          
                seterror(t(error.code))
                //TODO: limpiar campos¿?¿?¿?¿
                if (error.code.includes('auth/invalid-email')) { seterror(t('AUTH_ERROR_INVALIDEMAIL')) }
                if (error.code.includes('auth/wrong-password')) seterror(t('AUTH_ERROR_WRONGPASS'))
                if (error.code.includes('auth/user-not-found')) seterror(t('AUTH_ERROR_USERNOTFOUND'))
            });
    };

    return (
        <Layout style={{ height: "90vh" }}>
        
            <Row justify='center' align='middle' style={{ height: "80vh" }}>
                <Col span={10} style={{ "textAlign": "center" }}>
                    <img src={`/assets/img/${pathPart(window.location.host, 0)}/logo.jpg`} alt="" />
                </Col>
                <Col span={10} style={{ display: "flex", "justifyContent": "center" }}>
                    <div className='d-flex bg-light align-items-center text-center' style={{ padding: 24, width: 400 }}>
                        {(
                            <Form className="login-form" onFinish={withEmail}>
                                <Form.Item>
                                    <h1 className='text-primary'>{t('LOGIN')}</h1>
                                </Form.Item>
                                {error ? <Form.Item>{error}</Form.Item> : null}
                                <Form.Item
                                    name="user"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input
                                        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                        placeholder={t('USERNAME')}
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined style={{ color: "rgba(0,0,0,.25)" }}
                                            />
                                        }
                                        type="password"
                                        placeholder={t('PASSWORD')}
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{ marginTop: "15px", marginRight: "100px" }}
                                    >
                                        {t('BUTTON_LOGIN')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>

            <Footer className="fixed-bottom d-none d-sm-block" style={{ textAlign: "center" }}>
                Powered by <a href='https://www.newba.cloud/' target="_blank" rel="noopener noreferrer"> Newba ICT</a>
            </Footer>

        </Layout>
    );
};

export default Login;