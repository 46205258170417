import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { useFirebaseAuth } from '../context/AuthContext';
import { deleteData, deleteFolder, getImageUrl, saveData, uploadFile } from '../context/firebaseConfig';

import { Button, Collapse, Form, Input, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';

import ImageUrl from './ImageUrl';

const { Panel } = Collapse; 

const CardLodgeForm = (props) => {
    const { t } = useTranslation();
    const { dealerId, channelId, templateNamespace, cb, linkUrl } = props;
    const a = useFirebaseAuth();
    const company = a.company;
    const [lines, setLines] = useState([]);
    const [file, setFile] = useState();
    // eslint-disable-next-line no-unused-vars
    const [dealId, setDealId] = useState(dealerId);
    // eslint-disable-next-line no-unused-vars
    const [chaId, setChaId] = useState(channelId);
    // eslint-disable-next-line no-unused-vars
    const [temNam, setTemNam] = useState(templateNamespace);
    // eslint-disable-next-line no-unused-vars
    const [img, setImg] = useState();
    // eslint-disable-next-line no-unused-vars
    const [linUr, setLinUr] = useState(linkUrl)

    useEffect(() => {
        const searchImg = async () => {
            let pretty = [];
            const i = await getImageUrl(`logos/${company.companyID}/${dealId}`);
            setImg(i);
            pretty.push(<ImageUrl imgsrc={i || ""} sufix={dealerId} key='imgUrl_1' callback={setFile} />)
            setLines(pretty);
        }
        searchImg()
        // pretty.push(<ImageUrl imgsrc={cd?.imageUrl_en||""} urllnk={cd?.url_en||""} sufix='en' key='imgUrl_3' callback={handleCallback} />)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [form] = Form.useForm();
    const onFinish = async (values) => {
        if (!values.dealerId && !dealId) {
            cb(-1, t('NO_LODGE_ID'));
            return;
        }
        // Si tenemos imagen la subimos
        if ((values?.dragger && values.dragger[0]) || file) {
            const ret = await uploadFile(`logos/${company.companyID}/${values.dealerId || dealId}`, values.dragger[0].originFileObj || file);
            if (ret == null) {
                cb(-1, t('CANT_UPLOAD_IMAGE'));
                return;
            }
        }

        // maquetamos los datos y parriba
        let data = { dealerId: values.dealerId || dealId }
        if (values.channelId || chaId) { data.channelId = values.channelId || chaId; }
        if (values.templateNamespace || temNam) { data.templateNamespace = values.templateNamespace || temNam; }
        if (values.linkUrl || linUr) { data.linkUrl = values.linkUrl || linUr; }
        const ret = await saveData({ coll: `companies/${company.companyID}/dealership/${values.dealerId || dealId}`, data, update: false })
        if (!ret) {
            cb(-1);
            return;
        }
        cb(0);
        // if (ret) form.resetFields();
    }

    const deleteMe = async () => {
        await deleteFolder(`logos/${company.companyID}/${dealId}`);
        await deleteData({ coll: `companies/${company.companyID}/dealership/${dealId}` })
        // const ret = await deleteData({ coll: `companies/${company.companyID}/dealership/${dealId}` })
        cb(0);
    }

    return (
        <Collapse style={{ width: '100%'}} acordion>
            {/* <Panel header={dealerId} key='1' extra={  */}
            <Panel
                style={{backgroundColor: "#e8f2f9"}}
                header={dealerId ? `${t('LODGE_NUMBER')} ${dealerId}` : t('NEW_LODGE')}
                key='1'
                extra={
                    <Popconfirm
                        title={t('DELETE_DEALER_TITLE')}
                        description={t('DELETE_DEALER_MSG')}
                        onConfirm={deleteMe}
                        onCancel={() => { }}
                        okText={t('YES')}
                        cancelText={t('NO')}
                    >
                        <DeleteOutlined onClick={() => { }} />
                    </Popconfirm>
                } >
                <Form
                    form={form}
                    name={`form-${dealId}`}
                    onFinish={onFinish}
                    scrollToFirstError
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 15 }}
                    layout="horizontal"
                >

                    {/* NOTA IMPORTANTE: defaultValue en los campos channelId y templateNamespace de la configuración del chat, podrá estar configurada en Company si todos los concesioanrios utilizan el mismo channel de MB y un mismo flow, o en DEALERSHIP, si cada concesionario necesita de su propio channel porque tienen telefónos distintos para realizar las encuestas. Para estos campos se añade un mensaje por defecto en el caso que el campo esté en blanco*/}

                    {lines}

                    <Form.Item label={t('LODGE_ID')} tooltip={t('LODGE_ID_TOOLTIP')} name='dealerId'>
                        <Input defaultValue={dealId} disabled={dealId ? true : false} />
                    </Form.Item>

                    <Form.Item label={t('CHANNEL_ID')} tooltip={t('CHANNEL_ID_TOOLTIP')} name='channelId'>
                        <Input defaultValue={chaId || t('DEALER_MSG_NO_CONFIG')} />
                    </Form.Item>

                    <Form.Item label={t('TEMPLATE_NAMESPACE')} tooltip={t('TEMPLATE_NAMESPACE_TOOLTIP')} name='templateNamespace'>
                        <Input defaultValue={temNam || t('DEALER_MSG_NO_CONFIG')} />
                    </Form.Item>

                    <Form.Item label={t('LINK_URL')} tooltip={t('LINK_URL_TOOLTIP')} name='linkUrl'>
                        <Input defaultValue={linUr} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 15 }}>
                        <Button type="primary" htmlType="submit" >{t('BUTTON_SAVE')}</Button>
                    </Form.Item>

                </Form>
            </Panel>
        </Collapse>
    )
}

export default CardLodgeForm;