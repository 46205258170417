import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFirebaseAuth } from "../context/AuthContext";
import { getBearer } from "../context/firebaseConfig";

// import { Button, Col, Progress, Row, Spin } from "antd";
import { Button, Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import axios from "axios";


const CronCard = (props) => {
    const a = useFirebaseAuth();
    const companyId = a.company.companyPath
    const { cron } = props;
    const { t } = useTranslation();
    const [btnClass, setBtnClass] = useState(' depth ');
    const [disabled, setDisabled] = useState(false);
    const [percent, setPercent] = useState(0);
    const [results, setResults] = useState(null);
    // const [showProgres, setShowProgres] = useState(false);
    const BASE = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false)

    /*
    -Pone en disabled el boton para que no le den más veces
    -Habilita el percent y se pone a cargarlo como las grecas
    -Llama al servicio web correspondiente
    -Espera respuesta
    -Oculta barra de preogreso
    -Muestra resultados en su lugar
    -Habilita botón
    */
    const run = async () => {
        if (disabled) return;

        try {
            setResults(null);
            setBtnClass("depth active");
            setDisabled(true);
            // setShowProgres(true);
            setPercent(percent + 2);
            setLoading(true);
            const bearer = await getBearer();
            const URL = `${BASE}/${cron}/${companyId}/poll`;

            const cfg = {
                method: "post",
                url: URL,
                data: {},
                headers: {
                    'content-type': 'Application/json',
                    "authorization": `Bearer ${bearer}`
                },
            }

            const res = await axios(cfg)
            const detail = res?.data?.detail;
            // console.log(detail)
            // Los errores y el total no nos interesan de momento => setResults(`${t('CRON_RESULTS').replace('#TOTAL#', detail.total).replace('#ERRORS#', detail.errors)}`)
            setResults(`${t('CRON_RESULTS').replace('#POLLS#', detail.polls)}`)
        } catch (error) {
            setResults(error)
        }
        setBtnClass("depth");
        setDisabled(false);
        // setShowProgres(false);
        setLoading(false)
    }

    return (
        <Content style={{ minHeight: '15vh', marginTop: '5vh', backgroundColor: "#e8f2f9", color: "#2d3033" }} className="content">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={4}>
                    <Button
                        onClick={() => { run(); }}
                        className={btnClass}
                    >
                        &#10004;
                    </Button>
                </Col>
                <Col span={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t(cron.toUpperCase() || 'NO_CRON_NAME')}
                    </span>
                    <span>
                        {t(`${cron.toUpperCase()}_DESCRIPTION` || 'NO_CRON_DESCRIPTION')}
                    </span>
                </Col>

                <Col span={8}>
                    {
                        loading ?
                            <img
                                src={`/assets/img/progress4.gif`}
                                alt="Barra de progreso o Loading durante la carga de resultados"
                                style={{ height: "50%", alignSelf: "center", marginLeft: "25%" }}
                            />
                            : <></>
                    }
                    {/* // ALTERNATIVAS A GIF con BARRA DE PROGRESO, Un Spinner O Una progressBar*/}
                    {/* {
                        loading ?
                            <Spin tip={t('PROCESSING')} style={{ marginLeft: "50%", textAlign: "center" }} size="large">
                            </Spin>
                            : <></>
                    } */}
                    {/* {showProgres &&
                        <Progress
                            percent={percent}
                            status="active"
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068',
                            }}
                        />
                    } */}
                    
                        {
                            results &&
                            <p style={{ textAlign: "center", paddingTop: "3%", marginLeft: "14%" }}>{results}</p>
                        }
                    
                </Col>
            </Row>
        </Content>
    )
}

export default CronCard;