import { Route, Routes } from 'react-router-dom'

import Login from './views/Login'

import ProtectedRoute from './ProtectedRoute'
import NotFound from './views/NotFound'

import { AuthContext } from './context/AuthContext'
// import Dashboard from './views/Dashboard'
import Company from './views/Company'
import CarDealer from './views/CarDealer'
import Crons from './views/Crons'
import CardLodge from './views/CardLodge'

const Views = () => {
    return (
        <AuthContext>
            <Routes>
                <Route path='/' element={<Login />} key='toute_5' />
                {/* <Route element={<ProtectedRoute p='dashboard'/>} key='poute_2'>
                    <Route path='/dashboard' element={<Dashboard />} key='toute_8'/>
                </Route> */}
                <Route element={<ProtectedRoute p='company' />} key='poute_2'>
                    <Route path='/company' element={<Company />} key='toute_8' />
                </Route>
                <Route element={<ProtectedRoute p='cardealer' />} key='poute_2'>
                    <Route path='/cardealer' element={<CarDealer />} key='toute_8' />
                </Route>
                <Route element={<ProtectedRoute p='cardlodge' />} key='poute_2'>
                    {/* la ruta para alojamientos es una copia de cardealer. Se puede reutilizar el componente cardealer pq solo cambiamos el titulo del Menu, pero la ruta aparecería poco coherente, por eso duplico la ruta. Si reutilizamos el componente habria que modificarlo para que muestre los campos del formularios con "concesionarios" o "alojamientos" en funcion de la compañia. Para no tocar el original duplico tb el componente*/}
                    {/* <Route path='/cardlodge' element={<CarDealer />} key='toute_8' /> */}
                    <Route path='/cardlodge' element={<CardLodge />} key='toute_8' />
                </Route>
                <Route element={<ProtectedRoute p='cron' />} key='poute_2'>
                    <Route path='/cron' element={<Crons />} key='toute_8' />
                </Route>
                <Route path='*' element={<NotFound />} key='toute_404' />
            </Routes>
        </AuthContext>
    )
}

export default Views;