import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logOut } from "../../context/firebaseConfig";
import { useFirebaseAuth } from "../../context/AuthContext";

import { Button, Col, Row } from "antd";
import { Header } from "antd/es/layout/layout";

import LanguageSelector from "../langSelector/LanguageSelector";

const LoggedHeader = () => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const cfg = useFirebaseAuth();

    const laGordadeRojo = () => {
        logOut();
        nav('/');
    }

    return (
        <Header style={{ backgroundColor: 'white' }}>
            <Row>
                <Col flex={20} >
                    <img
                        src={`/assets/img/${cfg?.company?.companyPath}/logo3.jpg`}
                        alt={cfg?.company?.companyName}
                        style={{ backgroundColor: "#FFF", height: "50px" }}
                    />
                </Col>
                <Col flex={0.6} style={{ marginTop: "8px" }}>
                    <LanguageSelector />
                </Col>
                <Col flex={0.1}>
                    <Button
                        onClick={() => { laGordadeRojo(); }}
                        key="logout"
                        type="primary"
                        style={{ marginRight: '15px', marginLeft: '15px' }}
                    >
                        {t('BUTTON_LOGOUT')}
                    </Button>
                </Col>
            </Row>
        </Header>
    )
}

export default LoggedHeader